<template>
  <div class="wrap">
    <table class="table-inner">
      <colgroup width="50%"></colgroup>
      <colgroup width="25%"></colgroup>
      <colgroup width="25%"></colgroup>
      <thead>
        <th>Название</th>
        <th>Дата проведения</th>
        <th>Статус</th>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id" @dblclick="viewCard(item.id)">
          <td>{{ item.name }}</td>
          <td>{{ $momentFormat(item.eventFirstDate, 'DD MMMM YYYY') }}</td>
          <td>{{ item.place }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import API from '../api/widgetElement';

  export default {
    name: 'Widget10',
    components: {},
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (/*newValue*/) {
        this.loadItems();
      },
    },
    methods: {
      loadItems() {
        API.getEventSimple({ countryId: this.countryId }).then((response) => {
          this.items = response.data;
        });
      },
      viewCard(id) {
        this.$router.push({ name: 'EventCard', params: { id: id, action: 'view' } });
      },
    },
    data() {
      return {
        items: [],
      };
    },
    created() {
      this.loadItems();
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/colors';
  .wrap {
    max-height: 165px;
  }
  .table-inner {
    min-width: 620px;

    width: 100%;
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $dark;
    padding: 16px;
    .active {
      background-color: #ededed;
    }
    th {
      padding-bottom: 16px;
    }
    th:nth-child(1),
    td:nth-child(1) {
      padding-left: 12px;
    }
    tbody tr:nth-of-type(even) {
      background: #ededed;
    }
  }
</style>
